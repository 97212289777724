import {
  DisabledByDefault,
  HelpCenter,
  PriceChange,
  ArrowBack,
  ExitToApp,
  Home,
  Inventory2,
  SvgIconComponent,
  Restaurant,
  SyncProblem,
  Category,
} from "@mui/icons-material";

export type MenuItem = {
  text: string;
  icon: SvgIconComponent;
  url: string;
};

export const functionsList: MenuItem[] = [
  {
    text: "Index",
    icon: Home,
    url: "/",
  },
  {
    text: "Cancel Purchase Order",
    icon: DisabledByDefault,
    url: "/po-canceller",
  },
  {
    text: "Manage Cost Centres",
    icon: Restaurant,
    url: "/cost-centres",
  },
  {
    text: "Manage Price Changes",
    icon: PriceChange,
    url: "/price-changes",
  },
  {
    text: "Product Implementation",
    icon: Inventory2,
    url: "/product-implementation",
  },
  {
    text: "Ungrouped Products",
    icon: Category,
    url: "/ungrouped-products",
  },
  {
    text: "GRV Failures",
    icon: SyncProblem,
    url: "/grvs-not-in-stock",
  },
  {
    text: "RTV Failures",
    icon: SyncProblem,
    url: "/rtvs-not-in-stock",
  },
];

export const supportList: MenuItem[] = [
  {
    text: "Help & Support",
    icon: HelpCenter,
    url: encodeURI(
      "mailto:daniels@nandos.co.uk;blaind@nandos.co.uk;itservicedesk@nandos.co.uk?subject=Omnitool Error!&body=Please specify below the following: Time of Failure, Steps taken to replicate and items affected"
    ),
  },
  {
    text: "Back to Nossa Casa",
    icon: ArrowBack,
    url: "https://nandoca.family",
  },
  {
    text: "Sign Out",
    icon: ExitToApp,
    url: "/api/auth/signout",
  },
];
