import {
  Box,
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Menu, ChevronLeft } from "@mui/icons-material";
import Link from "next/link";
import { useState } from "react";
import { functionsList, supportList } from "./navigationItems";

const drawerWidth = 240;

type Props = {
  children: React.ReactNode;
};

export const NavigationDrawer = ({ children }: Props) => {
  const [open, setOpen] = useState(false);

  const handleDrawer = () => setOpen((open) => !open);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            sx={{ mr: 2 }}
          >
            {open ? <ChevronLeft /> : <Menu />}
          </IconButton>
          <Link href="/">
            <Typography
              variant="h6"
              noWrap
              component="div"
              style={{ cursor: "pointer" }}
            >
              OmniTool
            </Typography>
          </Link>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            marginTop: "64px",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <List>
          {functionsList.map((item) => {
            const { text, icon: Icon, url } = item;
            return (
              <Link key={text} href={url}>
                <a>
                  <ListItem button>
                    <Icon />
                    <ListItemText primary={text} />
                  </ListItem>
                </a>
              </Link>
            );
          })}
        </List>
        <Divider />
        <List>
          {supportList.map((item) => {
            const { text, icon: Icon, url } = item;
            return (
              <Link key={text} href={url}>
                <a>
                  <ListItem button>
                    <Icon />
                    <ListItemText primary={text} />
                  </ListItem>
                </a>
              </Link>
            );
          })}
        </List>
      </Drawer>
      {children}
    </Box>
  );
};
