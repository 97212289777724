import type { NextPage } from "next";
import { Layout } from "components/layout";
import {
  functionsList,
  supportList,
  MenuItem,
} from "components/navigationDrawer/navigationItems";
import { Card, Container, Divider, Grid, Typography } from "@mui/material";
import Link from "next/link";

type IconGridProps = {
  items: MenuItem[];
};

const IconGrid = ({ items }: IconGridProps) => (
  <Grid container spacing={3}>
    {items.map(({ url, text, icon: Icon }) => (
      <Grid key={url} item xs={12} sm={4} lg={3}>
        <Link href={url}>
          <Card sx={{ display: "flex", cursor: "pointer" }}>
            <Icon sx={{ m: 2 }} fontSize="large" />
            <Typography sx={{ alignSelf: "center" }}>{text}</Typography>
          </Card>
        </Link>
      </Grid>
    ))}
  </Grid>
);

const Home: NextPage = () => {
  return (
    <Layout>
      <Container>
        <IconGrid items={functionsList} />
        <Divider sx={{ my: 2 }} />
        <IconGrid items={supportList} />
      </Container>
    </Layout>
  );
};

export default Home;
