import { CircularProgress, Container, Typography } from "@mui/material";
import { NavigationDrawer } from "components/navigationDrawer";
import { Error } from "components/error";
import styles from "styles/Home.module.css";

type Props = {
  children: React.ReactNode;
  isLoading?: boolean;
  isError?: boolean;
  title?: string;
  isFetched?: boolean;
};

export const Layout: React.FC<Props> = ({
  isLoading = false,
  isError = false,
  isFetched = true,
  title,
  children,
}: Props) => (
  <div className={styles.container}>
    <NavigationDrawer>
      <main className={styles.main}>
        <Container>
          {title && (
            <Typography
              variant="h3"
              component="h1"
              paddingBottom={2}
              textAlign="center"
            >
              {title}
            </Typography>
          )}
          {isLoading && (
            <div className={styles.loadingContainer}>
              <CircularProgress />
            </div>
          )}
          {isError && <Error />}
          {isFetched && children}
        </Container>
      </main>
    </NavigationDrawer>
  </div>
);

export default Layout;
